<template>
  <div>
    <component :is="getThemeMode.layout"></component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getThemeMode']),
  },
};
</script>
